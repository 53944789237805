import React, { useEffect } from 'react';
import { Paper, Typography, TableCell, TableRow, TableHead, Table, TableBody, TextField } from '@mui/material';
import { LoadingButton, DateTimePicker } from '@mui/lab';
import { campaignTimeService } from 'services/campaignTime';
import { useMutation, useQuery } from 'react-query';
import { Controller, useForm } from 'react-hook-form';
import { Spinner } from 'components';
import { DateTime } from 'luxon';
import { useSnackbar } from 'notistack';

const CampaignTime = () => {
  const { enqueueSnackbar } = useSnackbar();

  const { data, isFetching, refetch } = useQuery(['campaignTimeService.fetchCampaignTime'], () =>
    campaignTimeService.fetchCampaignTime(),
  );

  useEffect(() => {
    setTimeout(refetch, 200)
  }, [])

  const { mutate: updateCampaignTime, isLoading } = useMutation(campaignTimeService.updateCampaignTime, {
    onSuccess: () => {
      enqueueSnackbar('Update campaign time successfully', { variant: 'success' });
      refetch();
    },
  });

  const { control, setValue, handleSubmit } = useForm({ mode: 'onChange' });

  const handleClickSave = () => {
    handleSubmit((values) => {
      const { open_time, double_limit_round_time, fcfs_round_time, close_time } = values;
      updateCampaignTime({
        open_time: open_time.toISO(),
        double_limit_round_time: double_limit_round_time.toISO(),
        fcfs_round_time: fcfs_round_time.toISO(),
        close_time: close_time.toISO(),
      });
    })();
  };

  React.useEffect(() => {
    if (data) {
      setValue('open_time', DateTime.fromISO(data.open_time, { zone: 'utc' }));
      setValue('double_limit_round_time', DateTime.fromISO(data.double_limit_round_time, { zone: 'utc' }));
      setValue('fcfs_round_time', DateTime.fromISO(data.fcfs_round_time, { zone: 'utc' }));
      setValue('close_time', DateTime.fromISO(data.close_time, { zone: 'utc' }));
    }
  }, [setValue, data]);

  return (
    <Paper className='p-6'>
      <Typography variant='h4' className='mb-1'>
        Open time
      </Typography>
      <Typography className='mb-6'>Update and config campaign open time</Typography>
      <Spinner spinning={isFetching}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align='center'>Open Time</TableCell>
              <TableCell align='center'>Double Limit Round Time</TableCell>
              <TableCell align='center'>FCFS Round Time</TableCell>
              <TableCell align='center'>Close Time</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableCell className='text-center'>
              <Controller
                name={'open_time'}
                defaultValue=''
                control={control}
                render={({ field }) => (
                  <DateTimePicker {...field} renderInput={(params) => <TextField {...params} />} />
                )}
              />
            </TableCell>
            <TableCell className='text-center'>
              <Controller
                name={'double_limit_round_time'}
                defaultValue=''
                control={control}
                render={({ field }) => (
                  <DateTimePicker {...field} renderInput={(params) => <TextField {...params} />} />
                )}
              />
            </TableCell>
            <TableCell className='text-center'>
              <Controller
                name={'fcfs_round_time'}
                defaultValue=''
                control={control}
                render={({ field }) => (
                  <DateTimePicker {...field} renderInput={(params) => <TextField {...params} />} />
                )}
              />
            </TableCell>
            <TableCell className='text-center'>
              <Controller
                name={'close_time'}
                defaultValue=''
                control={control}
                render={({ field }) => (
                  <DateTimePicker {...field} renderInput={(params) => <TextField {...params} />} />
                )}
              />
            </TableCell>
          </TableBody>
        </Table>
      </Spinner>

      <div className='mt-6' align='right'>
        <LoadingButton className='w-24' loading={isLoading} onClick={handleClickSave}>
          Save
        </LoadingButton>
      </div>
    </Paper>
  );
};
export default CampaignTime;
