import { getClient } from './axios';

const fetchOrdersDetail = (params) => getClient()?.get(`/admin/order_details`, { params });
const ordersAction = ({ id, ...body }) => {
  getClient()?.put(`/admin/order/${id}`, body, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
};
export const ordersService = {
  fetchOrdersDetail,
  ordersAction,
};
