import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Button } from '@mui/material';
import { FormControl } from '@mui/material';
import { walletUsersService } from 'services/walletUsers';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { store } from 'reducers';
import { enqueueSnackbar } from 'reducers/coreui';
import { VIPLevels } from './WhitelistWalletUser'

const UpdateLevelCard = ({ walletUser }) => {
    const [givenLevelId, setGivenLevelId] = React.useState(walletUser.levelId);
    const [selectingLevelId, setSelectingLevelId] = React.useState(walletUser.levelId);

    const { role } = useSelector(({ profile }) => profile);
    const [allowedVIPLevels, setAllowedVIPLevels] = useState(VIPLevels)

    // useEffect(() => {
    //     if (role && role === 10) {
    //         setAllowedVIPLevels(x => [...x, {
    //             id: 7,
    //             name: "Super Admin"
    //         }])
    //     }
    // }, [role])

    const handleChange = (event) => {
        setSelectingLevelId(event.target.value);
    };

    const handleUpdate = useCallback(async () => {
        try {
            const resp = await walletUsersService.updateWalletUser({
                "walletAddr": walletUser.walletAddr,
                "email": walletUser.email,
                "levelId": selectingLevelId
            })
            store.dispatch(
                enqueueSnackbar({
                    message: resp.message,
                    variant: 'success',
                })
            );

            setGivenLevelId(parseInt(selectingLevelId))
        } catch (ex) {
            store.dispatch(
                enqueueSnackbar({
                    message: ex.toString(),
                    variant: 'error',
                })
            );

        }
    }, [selectingLevelId, walletUser])

    return (
        <FormControl>
            <InputLabel id="demo-simple-select-label">VIP</InputLabel>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectingLevelId}
                label="VIP"
                onChange={handleChange}
            >
                {allowedVIPLevels.map((item) =>
                    <MenuItem 
                    key={item.id}
                    value={item.id}>{item.name}</MenuItem>)}
            </Select>
            <Button
                disabled={selectingLevelId === givenLevelId}
                onClick={handleUpdate}>Update</Button>
        </FormControl>
    )
}

export default UpdateLevelCard;