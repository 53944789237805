import { Home } from 'views/Home';
import { WhitelistWalletUser } from 'views/WhitelistWalletUser';
import { CampaignTime } from 'views/CampaignTime';

const privateRoute = {
  home: {
    path: '/home',
    component: Home,
    name: 'Dashboard',
  },
  whitelistWalletUser: {
    path: '/whitelist-wallet-user',
    component: WhitelistWalletUser,
    name: 'Whitelist',
  },
  campaignTime: {
    path: '/campaigntime',
    component: CampaignTime,
    name: 'Campaign Time',
    requireSuperAdmin: true,
  },
};

export default privateRoute;
