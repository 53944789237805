import axios from 'axios';
import { store } from 'reducers';
import { enqueueSnackbar } from 'reducers/coreui';
import { signOut } from 'reducers/profile';
import { stringify } from 'query-string';
import { AUTH_API_URL } from 'env';

const onError = ({ response }) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  if (response) {
    const { status, statusText, data } = response;
    if (status === 401) {
      store.dispatch(signOut());
    } else {
      const { message = `${status} - ${statusText}` } = data;
      store.dispatch(
        enqueueSnackbar({
          message: message.charAt(0).toUpperCase() + message.slice(1),
          variant: 'error',
          key: 'axios',
        }),
      );
    }
  } else {
    store.dispatch(enqueueSnackbar({ message: `Cannot connect to Server`, variant: 'error', key: 'axios' }));
  }
  return Promise.reject(response);
};

const beforeRequest = (config) => {
  const { isLoggedIn, access_token } = store.getState().profile;
  if (isLoggedIn) {
    Object.assign(config.headers, { Authorization: `Bearer ${access_token}` });
  }
  if (config.data instanceof FormData) {
    Object.assign(config.headers, { 'Content-Type': 'multipart/form-data' });
  }
  return config;
};

var clientApiUrl
const setAPIURL = (_apiURL) => {
  clientApiUrl = _apiURL
}

const getClient = () => {
  if (!clientApiUrl) {
    return
  }

  const client = axios.create({
    baseURL: clientApiUrl,
    paramsSerializer: (params) => stringify(params, { arrayFormat: 'index' }),
  });
  client.interceptors.request.use(beforeRequest);
  
  [client].forEach((_client) => {
    _client.interceptors.response.use(({ data }) => {
      return data.data ?? data;
    }, onError);
  });

  return client
}

// this auth client is used for login
// the URL it points to is always available and contains users login credentials
const clientAuth = axios.create({
  baseURL: AUTH_API_URL,
  paramsSerializer: (params) => stringify(params, { arrayFormat: 'index' }),
});
clientAuth.interceptors.request.use(beforeRequest);

[clientAuth].forEach((_client) => {
  _client.interceptors.response.use(({ data }) => {
    return data.data ?? data;
  }, onError);
});

export { getClient, setAPIURL, clientAuth };
