import React, { useState, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { EnhancedTable } from 'components';
import { Divider, Button, Grid, Paper, TextField } from '@mui/material';
import { FormControl } from '@mui/material';
import { store } from 'reducers';
import { enqueueSnackbar } from 'reducers/coreui';
import { walletUsersService } from 'services/walletUsers';
import UpdateLevelCard from './UpdateLevelCard';
import UpdateWalletUser from './UpdateWalletUser';

export const VIPLevels = [
  { id: 1, name: 'VIP 1' },
  { id: 2, name: 'VIP 2' },
  { id: 3, name: 'VIP 3' },
  { id: 4, name: 'VIP 4' },
  { id: 5, name: 'VIP 5' },  
  { id: 6, name: 'VIP 6' },
  { id: 7, name: 'VIP 7' },
  { id: 8, name: 'VIP 8' },
  { id: 9, name: 'VIP 0' },
  { id: 10, name: 'No VIP' },
  { id: 11, name: 'Lucky Winner' },
];

const WhitelistWalletUser = () => {
  const [walletUsers, setWalletUsers] = useState();

  const { control, watch, setValue } = useForm({ mode: 'onChange' });
  const { walletAddressOrEmail = '' } = watch();

  const [whitelistFile, setWhitelistFile] = useState(undefined);

  const handleOnChange = (e) => {
    if (e.target.files.length > 0 && e.target.files[0]) {
      setWhitelistFile(e.target.files[0]);
    }
  };

  const uploadWhitelistFile = async () => {
    if (whitelistFile) {
      try {
        const resp = await walletUsersService.uploadWhitelistFile(whitelistFile);
        if (resp?.code === 200) {
          store.dispatch(
            enqueueSnackbar({
              message: resp.message,
              variant: 'success',
            }),
          );
        }
      } catch (ex) {
        console.error(ex);
      }
    } else {
      store.dispatch(
        enqueueSnackbar({
          message: 'Bulk whitelist is empty',
          variant: 'error',
        }),
      );
    }
  };

  const findWalletUsers = async () => {
    const resp = await walletUsersService.fetchWalletUsers(walletAddressOrEmail);
    setWalletUsers(resp.message);
  };

  const findWalletUsersAfterWhitelist = async (walletAddr) => {
    setValue('walletAddressOrEmail', walletAddr);
    const resp = await walletUsersService.fetchWalletUsers(walletAddr);
    setWalletUsers(resp.message);
  };

  useEffect(() => {}, [walletUsers]);

  return (
    <Paper className='p-6'>
      <Grid container spacing={3} mb={3}>
        <Grid item xs={5}>
          <Controller
            name='walletAddressOrEmail'
            defaultValue=''
            control={control}
            render={({ field }) => (
              <FormControl fullWidth>
                <label>Wallet Address or Email</label>
                <TextField {...field} />
              </FormControl>
            )}
          />
        </Grid>
        <Grid item xs={3}>
          <Controller
            name='walletAddress'
            defaultValue=''
            control={control}
            render={() => (
              <FormControl>
                <label>.</label>
                <Button disabled={walletAddressOrEmail === ''} onClick={findWalletUsers}>
                  Find
                </Button>
              </FormControl>
            )}
          />
        </Grid>
        <Grid item xs={3}>
          <Controller
            name='whitelistFile'
            control={control}
            render={({ field }) => (
              <FormControl>
                Whitelist bulk
                <div variant='contained' component='label'>
                  <input className='w-60' {...field} type='file' onChange={handleOnChange} />
                </div>
                <Button className='mt-2' onClick={uploadWhitelistFile}>Upload</Button>
              </FormControl>
            )}
          />
        </Grid>
      </Grid>

      <Divider className='bg-neutral-10 my-2' />
      <UpdateWalletUser findWalletUsersAfterWhitelist={findWalletUsersAfterWhitelist} />
      <Divider className='bg-neutral-10' />

      <EnhancedTable
        data={Array.isArray(walletUsers) ? walletUsers : []}
        columns={[
          {
            name: 'walletAddr',
            label: 'Found Wallet Address',
          },
          {
            name: 'email',
            label: 'Email',
          },
          {
            name: 'levelId',
            label: 'Level ID',
            options: {
              customBodyRender: (value, { rowIndex }) => {
                const walletUser = walletUsers[rowIndex];
                return <UpdateLevelCard key={walletUser.levelId} walletUser={walletUsers[rowIndex]} />;
              },
            },
          },
        ]}
        options={{
          ...EnhancedTable.options,
          fixedHeader: true,
          tableBodyHeight: '500px',
        }}
      />
    </Paper>
  );
};

export default WhitelistWalletUser;
