import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { Divider, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { signOut } from 'reducers/profile';
import { privateRoute } from 'routes';

import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';

const MenuItem = ({ name, icon, path, onClick }) => {
  const classes = useStyles();
  const location = useLocation();
  return (
    <ListItemButton
      classes={classes}
      component={path ? Link : undefined}
      to={path}
      selected={location.pathname.startsWith(path)}
      onClick={onClick}
    >
      <ListItemIcon className='min-w-min w-8'>{icon}</ListItemIcon>
      <ListItemText classes={{ primary: 'font-medium' }}>{name}</ListItemText>
    </ListItemButton>
  );
};

const Menu = () => {
  const dispatch = useDispatch();
  const { home, whitelistWalletUser, campaignTime } = privateRoute;

  const { role } = useSelector(({ profile }) => profile);
  const [allowedRoutes, setAllowedRoutes] = useState(privateRoute)

  React.useEffect(() => {
    if (role) {
      // if this is normal user
      if (role === 1) {
        let _allowedRoutes = {}

        // take only allowed routes - non super admin routes
        for (const route in privateRoute) {
          const routeDetail = privateRoute[route]
          if (routeDetail.requireSuperAdmin === true) {
            continue;
          } else {
            _allowedRoutes[route] = routeDetail
          }
        }

        setAllowedRoutes(_allowedRoutes)
      }
    }
  }, [role])

  return (
    <List>
      <MenuItem {...home} icon={<DashboardOutlinedIcon />} />
      <MenuItem {...whitelistWalletUser} icon={<BookmarkAddIcon />} />
      {allowedRoutes.campaignTime &&
        <MenuItem {...campaignTime} icon={<AccessTimeFilledIcon />} />
      }
      <Divider className='bg-neutral-100 my-2' />
      <MenuItem name='Logout' icon={<LogoutOutlinedIcon />} onClick={() => dispatch(signOut())} />
    </List>
  );
};

const useStyles = makeStyles({
  root: {
    minHeight: 40,
    marginBottom: 4,
    color: '#979BAF',
    '& .MuiListItemIcon-root': {
      color: '#979BAF',
    },
    '&:hover': {
      backgroundColor: '#5D73DD !important',
      color: '#FFFFFF',
      '& .MuiListItemIcon-root': {
        color: '#FFFFFF',
      },
    },
  },
  selected: {
    backgroundColor: '#5D73DD !important',
    color: '#FFFFFF',
    '& .MuiListItemIcon-root': {
      color: '#FFFFFF',
    },
  },
});

export default Menu;
