import { createSlice } from '@reduxjs/toolkit';
import { jwtDecode } from 'jwt-js-decode';


export const profileSlice = createSlice({
  name: 'profile',
  initialState: {},
  reducers: {
    signIn: (state, { payload: profile }) => {
      localStorage.setItem('profile', JSON.stringify(profile));
      const role = jwtDecode(profile.access_token).payload.role;
      return { ...state, ...profile, role, isLoggedIn: true };
    },
    signOut: (state) => {
      localStorage.removeItem('profile');
      return { isLoggedIn: false };
    },
  },
});

export const { signIn, signOut } = profileSlice.actions;

export default profileSlice.reducer;
