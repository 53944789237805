import React, { useState } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { useSelector } from 'react-redux';
import { AppHeader, AppMenu } from 'containers';
import { Darkmode } from 'components';
import { AppBar, Avatar, Fab, SwipeableDrawer, Typography } from '@mui/material';
import { browserHistory } from 'utils/history';
import { authRoute, privateRoute } from 'routes';
import { FormControl } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { store } from 'reducers';
import { CAMPAIGN_LIST, setCampaign, CAMPAIGN_ROUTES } from 'reducers/campaign';
import { API_URL } from 'env';
import { setAPIURL } from 'services/axios';

const PrivateLayout = () => {
  const { isLoggedIn, email, role } = useSelector(({ profile }) => profile);
  const { campaign } = useSelector(({ campaign }) => campaign);

  const [allowedRoutes, setAllowedRoutes] = useState(privateRoute);

  const [selectingCampaign, setSelectingCampaign] = React.useState(campaign);
  const handleChange = (event) => {
    setSelectingCampaign(event.target.value);
    store.dispatch(setCampaign(event.target.value));
  };

  React.useEffect(() => {
    if (role) {
      // if this is normal user
      if (role === 1) {
        let _allowedRoutes = {};

        // take only allowed routes - non super admin routes
        for (const route in privateRoute) {
          const routeDetail = privateRoute[route];
          if (routeDetail.requireSuperAdmin === true) {
            continue;
          } else {
            _allowedRoutes[route] = routeDetail;
          }
        }

        setAllowedRoutes(_allowedRoutes);
      }
    }
  }, [role]);

  React.useEffect(() => {
    if (!isLoggedIn) {
      browserHistory.replace(authRoute.login.path);
    }
  }, [isLoggedIn]);

  React.useEffect(() => {
    console.log(`${API_URL}${CAMPAIGN_ROUTES.get(campaign)}`);
    setAPIURL(`${API_URL}${CAMPAIGN_ROUTES.get(campaign)}`);
  }, [campaign]);

  const [isDrawerOpen, setIsDrawerOpen] = useState(true);

  return (
    <>
      <div className={'App Private-Layout' + (isDrawerOpen ? '' : ' Private-Layout--full-width')}>
        <SwipeableDrawer
          variant='persistent'
          anchor='left'
          className='App-Navbar'
          open={isDrawerOpen}
          onOpen={() => setIsDrawerOpen(true)}
          onClose={() => setIsDrawerOpen(false)}
        >
          <div className='flex flex-col items-center p-3'>
            <Avatar className='w-32 h-32 bg-yellow-600 text-5xl border-2 mb-2'>
              {email?.slice(0, 2).toUpperCase()}
            </Avatar>
            <Typography variant='h5' color='white'>
              {email}
            </Typography>
            <FormControl className='bg-white mt-4 border-none hover:border-none rounded-lg'>
              <Select
                labelId='demo-simple-select-label'
                id='demo-simple-select'
                value={selectingCampaign}
                label='Campaign'
                onChange={handleChange}
              >
                {Object.values(CAMPAIGN_LIST).map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <AppMenu />
          <div className='flex-1' />
          <div className='flex justify-center'>
            <Darkmode init />
          </div>
        </SwipeableDrawer>
        <main className='App-Main'>
          <AppBar position='sticky' elevation={0} color='default'>
            <AppHeader />
          </AppBar>
          <div className='App-Content'>
            <Switch>
              {Object.values(allowedRoutes).map(({ path, component }) => (
                <Route key={path} path={path} component={component} />
              ))}
              <Redirect from='/' to={allowedRoutes.home.path} />
            </Switch>
          </div>
        </main>
      </div>
      <Fab
        color='primary'
        aria-label='add'
        onClick={() => setIsDrawerOpen(!isDrawerOpen)}
        style={{
          zIndex: 9999,
          position: 'fixed',
          top: '24px',
          left: '24px',
        }}
      >
        {isDrawerOpen ? <ArrowBackIosNewIcon /> : <ArrowForwardIosIcon />}
      </Fab>
    </>
  );
};

export default PrivateLayout;
