import { configureStore } from '@reduxjs/toolkit';
import coreui from './coreui';
import profile from './profile';
import campaign from './campaign';

export const store = configureStore({
  reducer: {
    coreui,
    profile,
    campaign,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [],
      },
    }),
});
