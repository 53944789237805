import { createSlice } from '@reduxjs/toolkit';
import { API_URL } from 'env';
import { setAPIURL } from '../services/axios';

export const CAMPAIGN_LIST = {
  Ticket: 'Champion League tickets',
};

export const CAMPAIGN_ROUTES = new Map([
  [CAMPAIGN_LIST.Ticket, 'tickets'],
]);

const getDefaultCampaign = () => {
  const cachedCampaign = localStorage.getItem('campaign');

  // if cached campaign presents in the list -> return it
  if (CAMPAIGN_ROUTES.get(cachedCampaign)) {
    return cachedCampaign;
  } else {
    // else return the last campaign in the list
    const lastCampaign = Object.values(CAMPAIGN_LIST)[Object.values(CAMPAIGN_LIST).length - 1];
    localStorage.setItem('campaign', lastCampaign);
    return lastCampaign;
  }
};

export const campaignSlice = createSlice({
  name: 'campaign',
  initialState: {
    campaign: getDefaultCampaign(),
  },
  reducers: {
    setCampaign: (state, { payload: campaign }) => {
      localStorage.setItem('campaign', campaign);
      setAPIURL(`${API_URL}${CAMPAIGN_ROUTES.get(campaign)}`);
      setTimeout(() => window.location.reload(), 500);
      return { ...state, campaign };
    },
  },
});

export const { setCampaign } = campaignSlice.actions;

export default campaignSlice.reducer;
